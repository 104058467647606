<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="450px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline">Add Note</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <v-textarea
          label="Note"
          v-model="fields.text"
          rows="3"
          outlined
          :error="errors.hasOwnProperty('text')"
          :error-messages="errors['text']"
        ></v-textarea>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          @click="saveForm"
          type="submit"
          :loading="loading"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    customer: {
      type: Object,
      required: false,
      default: null,
    },
    itinerary: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
      fields: {
        text: "",
      },
      errors: {},
    };
  },

  methods: {
    openForm: function () {
      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.fields = {
        text: "",
      };
      this.errors = {};
    },

    saveForm: function () {
      const appId = this.$route.params.id;
      this.loading = true;
      let fields = this.fields;

      if (this.customer) {
        fields.customer_id = this.customer.id;
      } else {
        fields.itinerary_id = this.itinerary.id;
      }

      this.$store
        .dispatch("drum/notes/saveNote", {
          appId,
          fields,
        })
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>