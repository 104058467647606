<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete Note</v-card-title>
        <v-card-text>Are you sure you want to delete this note?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetForm">No, cancel</v-btn>
          <v-btn color="success" depressed :loading="loading" @click="saveForm"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    customer: {
      type: Object,
      required: false,
      default: null,
    },
    itinerary: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
      noteId: null,
    };
  },

  methods: {
    openForm: function (noteId) {
      this.dialog = true;
      this.noteId = noteId;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.noteId = null;
    },

    saveForm: function () {
      const appId = this.$route.params.id;
      this.loading = true;

      let fields = {
        noteId: this.noteId,
      };

      if (this.customer) {
        fields.customerId = this.customer.id;
      } else {
        fields.itineraryId = this.itinerary.id;
      }

      this.$store
        .dispatch("drum/notes/deleteNote", {
          appId,
          ...fields,
        })
        .then(() => this.resetForm())
        .catch(() => (this.loading = false));
    },
  },
};
</script>