<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline">Edit Basic Details</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form
          @submit.prevent="saveForm"
          method="post"
          id="basic-details-form"
        >
          <v-row>
            <v-col cols="6" class="pb-0">
              <v-text-field
                label="First Name"
                v-model="fields.first_name"
                outlined
                :error="errors.hasOwnProperty('first_name')"
                :error-messages="errors['first_name']"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pb-0">
              <v-text-field
                label="Last Name"
                v-model="fields.last_name"
                outlined
                :error="errors.hasOwnProperty('last_name')"
                :error-messages="errors['last_name']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-text-field
            label="Email"
            v-model="fields.email"
            type="email"
            outlined
            :error="errors.hasOwnProperty('email')"
            :error-messages="errors['email']"
          ></v-text-field>
          <v-text-field
            label="Phone"
            v-model="fields.phone"
            type="phone"
            outlined
            :error="errors.hasOwnProperty('phone')"
            :error-messages="errors['phone']"
          ></v-text-field>
          <v-card class="mb-7" outlined>
            <v-card-subtitle>Main Address</v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text>
              <v-text-field
                label="Address Line 1"
                v-model="fields.address1_1"
                outlined
                dense
                :error="errors.hasOwnProperty('address1_1')"
                :error-messages="errors['address1_1']"
              ></v-text-field>
              <v-text-field
                label="Address Line 2"
                v-model="fields.address1_2"
                outlined
                dense
                :error="errors.hasOwnProperty('address1_2')"
                :error-messages="errors['address1_2']"
              ></v-text-field>
              <v-text-field
                label="Address Line 3"
                v-model="fields.address1_3"
                outlined
                dense
                :error="errors.hasOwnProperty('address1_3')"
                :error-messages="errors['address1_3']"
              ></v-text-field>
              <v-text-field
                label="City"
                v-model="fields.address1_city"
                outlined
                dense
                :error="errors.hasOwnProperty('address1_city')"
                :error-messages="errors['address1_city']"
              ></v-text-field>
              <v-text-field
                label="County"
                v-model="fields.address1_county"
                outlined
                dense
                :error="errors.hasOwnProperty('address1_county')"
                :error-messages="errors['address1_county']"
              ></v-text-field>
              <v-text-field
                label="Postcode"
                v-model="fields.address1_postcode"
                outlined
                dense
                :error="errors.hasOwnProperty('address1_postcode')"
                :error-messages="errors['address1_postcode']"
              ></v-text-field>
              <v-text-field
                label="Country"
                v-model="fields.address1_country"
                outlined
                dense
                :error="errors.hasOwnProperty('address1_country')"
                :error-messages="errors['address1_country']"
              ></v-text-field>
            </v-card-text>
          </v-card>
          <v-card outlined>
            <v-card-subtitle>Secondary Address</v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text>
              <v-text-field
                label="Address Line 1"
                v-model="fields.address2_1"
                outlined
                dense
                :error="errors.hasOwnProperty('address2_1')"
                :error-messages="errors['address2_1']"
              ></v-text-field>
              <v-text-field
                label="Address Line 2"
                v-model="fields.address2_2"
                outlined
                dense
                :error="errors.hasOwnProperty('address2_2')"
                :error-messages="errors['address2_2']"
              ></v-text-field>
              <v-text-field
                label="Address Line 3"
                v-model="fields.address2_3"
                outlined
                dense
                :error="errors.hasOwnProperty('address2_3')"
                :error-messages="errors['address2_3']"
              ></v-text-field>
              <v-text-field
                label="City"
                v-model="fields.address2_city"
                outlined
                dense
                :error="errors.hasOwnProperty('address2_city')"
                :error-messages="errors['address2_city']"
              ></v-text-field>
              <v-text-field
                label="County"
                v-model="fields.address2_county"
                outlined
                dense
                :error="errors.hasOwnProperty('address2_county')"
                :error-messages="errors['address2_county']"
              ></v-text-field>
              <v-text-field
                label="Postcode"
                v-model="fields.address2_postcode"
                outlined
                dense
                :error="errors.hasOwnProperty('address2_postcode')"
                :error-messages="errors['address2_postcode']"
              ></v-text-field>
              <v-text-field
                label="Country"
                v-model="fields.address2_country"
                outlined
                dense
                :error="errors.hasOwnProperty('address2_country')"
                :error-messages="errors['address2_country']"
              ></v-text-field>
            </v-card-text>
          </v-card>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="basic-details-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
      fields: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address1_1: "",
        address1_2: "",
        address1_3: "",
        address1_city: "",
        address1_country: "",
        address1_county: "",
        address1_postcode: "",
        address2_1: "",
        address2_2: "",
        address2_3: "",
        address2_city: "",
        address2_country: "",
        address2_county: "",
        address2_postcode: "",
      },
      errors: {},
    };
  },

  created() {
    this.fields.first_name = this.customer.first_name;
    this.fields.last_name = this.customer.last_name;
    this.fields.email = this.customer.email;
    this.fields.phone = this.customer.phone;

    this.fields.address1_1 = this.customer.address1_1;
    this.fields.address1_2 = this.customer.address1_2;
    this.fields.address1_3 = this.customer.address1_3;
    this.fields.address1_city = this.customer.address1_city;
    this.fields.address1_county = this.customer.address1_county;
    this.fields.address1_postcode = this.customer.address1_postcode;
    this.fields.address1_country = this.customer.address1_country;

    this.fields.address2_1 = this.customer.address2_1;
    this.fields.address2_2 = this.customer.address2_2;
    this.fields.address2_3 = this.customer.address2_3;
    this.fields.address2_city = this.customer.address2_city;
    this.fields.address2_county = this.customer.address2_county;
    this.fields.address2_postcode = this.customer.address2_postcode;
    this.fields.address2_country = this.customer.address2_country;
  },

  methods: {
    openForm: function () {
      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.errors = {};
    },

    saveForm: function () {
      const appId = this.$route.params.id;
      this.loading = true;
      this.errors = {};

      this.$store
        .dispatch("drum/customers/saveCustomer", {
          appId,
          isEditing: true,
          customerId: this.customer.id,
          fields: this.fields,
        })
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>