<template>
  <div>
    <v-row>
      <v-col cols="6" class="pt-0 pb-0">
        <v-card>
          <v-toolbar flat dense dark color="accent">
            <v-toolbar-title>Basic Details</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn small depressed @click="$refs.basicDetailsForm.openForm()">
              <v-icon left>mdi-pencil</v-icon>Edit
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>{{ customer.full_name }}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{{ customer.email }}</td>
                </tr>
                <tr>
                  <th>Phone Number</th>
                  <td>{{ customer.phone }}</td>
                </tr>
                <tr>
                  <th>Main Address</th>
                  <td class="pt-2 pb-2">
                    <div>{{ customer.address1_1 }}</div>
                    <div v-if="customer.address1_2">
                      {{ customer.address1_2 }}
                    </div>
                    <div v-if="customer.address1_3">
                      {{ customer.address1_3 }}
                    </div>
                    <div>{{ customer.address1_city }}</div>
                    <div v-if="customer.address1_county">
                      {{ customer.address1_county }}
                    </div>
                    <div>{{ customer.address1_country }}</div>
                    <div>{{ customer.address1_postcode }}</div>
                  </td>
                </tr>
                <tr>
                  <th>Secondary Address</th>
                  <td class="pt-2 pb-2">
                    <div>{{ customer.address2_1 }}</div>
                    <div v-if="customer.address2_2">
                      {{ customer.address2_2 }}
                    </div>
                    <div v-if="customer.address2_3">
                      {{ customer.address2_3 }}
                    </div>
                    <div>{{ customer.address2_city }}</div>
                    <div v-if="customer.address2_county">
                      {{ customer.address2_county }}
                    </div>
                    <div>{{ customer.address2_country }}</div>
                    <div>{{ customer.address2_postcode }}</div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col cols="6" class="pt-0 pb-0">
        <v-card>
          <v-toolbar flat dense dark color="accent">
            <v-toolbar-title>Notes</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn small depressed @click="$refs.notesForm.openForm()">
              <v-icon left>mdi-plus</v-icon>Add
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <v-data-table
            :headers="tableHeaders"
            :items="customer.notes"
            no-data-text="There are no notes for this customer"
          >
            <template v-slot:item.text="{ item }">
              <span style="white-space: pre-wrap" v-html="item.text"></span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                    @click="$refs.deleteNote.openForm(item.id)"
                  >
                    <v-icon small>mdi-archive</v-icon>
                  </v-btn>
                </template>
                <span>Archive</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <BasicDetailsForm ref="basicDetailsForm" :customer="customer" />
    <NotesForm ref="notesForm" :customer="customer" />
    <DeleteNote ref="deleteNote" :customer="customer" />
  </div>
</template>

<script>
import BasicDetailsForm from "./BasicDetailsForm";
import NotesForm from "./NotesForm";
import DeleteNote from "../../../components/DeleteNote";

export default {
  components: {
    BasicDetailsForm,
    NotesForm,
    DeleteNote,
  },

  props: {
    customer: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      tableHeaders: [
        { text: "Date", value: "formatted_dates.created_at" },
        { text: "Note", value: "text" },
        { text: "Noted By", value: "created_by.full_name" },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
    };
  },
};
</script>
